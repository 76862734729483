@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    animation: anim2 1s cubic-bezier(.77,0,.175,1) 7s both;
    color: white;
}

.wrapperload{
    width: 500px;
    height: 100px;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
}

.wrapperload .loder_elem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


.wrapperload .loder_elem:nth-child(1){
    animation: anim 1.5s cubic-bezier(.77,0,.175,1) 1s both;
}

.wrapperload .loder_elem:nth-child(2){
    animation: anim 1.5s cubic-bezier(.77,0,.175,1) 3s both;
}

.wrapperload .loder_elem:nth-child(3){
    animation: anim 1.5s cubic-bezier(.77,0,.175,1) 5s both;
}



@keyframes anim{
    to{
        margin-top: -100px;
    }
}

@keyframes anim2{
    to{
        transform: translateY(-100%);
    }
    
}


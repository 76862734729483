.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    animation: anim2 1s cubic-bezier(.77,0,.175,1) 7s both;
    color: white;
}

.wrapperload{
    width: 500px;
    height: 100px;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
}

.wrapperload .loder_elem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


.wrapperload .loder_elem:nth-child(1){
    animation: anim 1.5s cubic-bezier(.77,0,.175,1) 1s both;
}

.wrapperload .loder_elem:nth-child(2){
    animation: anim 1.5s cubic-bezier(.77,0,.175,1) 3s both;
}

.wrapperload .loder_elem:nth-child(3){
    animation: anim 1.5s cubic-bezier(.77,0,.175,1) 5s both;
}



@keyframes anim{
    to{
        margin-top: -100px;
    }
}

@keyframes anim2{
    to{
        transform: translateY(-100%);
    }
    
}
